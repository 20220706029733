<template>
	<div
		class="bottom-navigation"
		v-if="!isMobileApp"
		:class="returnModificator"
	>
		<div class="bottom-navigation__item">
			<router-link to="/menu">
				<img
					src="@/assets/img/icons/bottom-nav-menu.svg"
					alt="Home"
				/>
				<span>Меню</span>
			</router-link>
		</div>
		<div class="bottom-navigation__item">
			<a
				src="/"
				:class="{'router-link-active': isActive}"
				@click="openPersonalLk()"
			>
				<img
					src="@/assets/img/icons/bottom-nav-profile.svg"
					alt="Search"
				/>
				<span>Профиль</span>
			</a>
		</div>
		<div
			class="bottom-navigation__item"
			v-if="showChat()"
		>
			<router-link to="/chat">
				<img
					src="@/assets/img/icons/bottom-nav-chat.svg"
					alt="Search"
				/>
				<span>Чат</span>
			</router-link>
		</div>
		<div class="bottom-navigation__item bottom-navigation__item--cart">
			<router-link to="/cart">
				<img
					src="@/assets/img/icons/bottom-nav-cart.svg"
					alt="Profile"
				/>
				<span class="bottom-navigation__item-cart">Корзина</span>
			</router-link>
			<span
				class="bottom-navigation__item-count"
				v-if="cartProductCount"
			>{{ cartProductCount }}</span>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "BottomNavigation",
	data()
	{
		return {
			isActive: false,
			isMobileApp: null,
		}
	},
	computed:
		{
			...mapGetters({
				cartProductsCount: "getCartProductsCount",
				myCartDetails: 'getMyCartDetails',
				currentBasket: 'getCurrentBasket',
				currentRestarauntId: "getRestarauntId",
			}),
			cartProductCount()
			{
				return this.currentBasket?.id ? this.myCartDetails.count : this.cartProductsCount
			},
			returnModificator()
			{
				if (this.$route.path === "/")
				{
					return ["bottom-navigation--application"];
				}
				if (this.$route.path === "/constructor/")
				{
					return ["bottom-navigation--constructor"];
				}
				if (this.$route.path === "/cart/" || this.$route.path === "/cart")
				{
					return ["bottom-navigation--cart"];
				}
				if (this.$route.path === "/menu" || this.$route.path.includes("/rest"))
				{
					return ["bottom-navigation--menu"];
				}
				if (this.$route.path === "/chat")
				{
					return ["bottom-navigation--chat"];
				}
				if (this.$route.path === "/order" || this.$route.path.includes("/order_confirmed"))
				{
					return ["bottom-navigation--order"]
				}
				if (this.$route.path === "/personal/")
				{
					return ["bottom-navigation--personal"]
				}
				if (this.$route.path === "/breakfast" || this.$route.path === "/dinner")
				{
					return ["bottom-navigation--breakfast"]
				}
			},
		},
	methods:
		{
			showChat()
			{
				if (this.currentRestarauntId === '7242' || this.currentRestarauntId === '16338' || this.$route.path === "/chat") return true;
			},
			openPersonalLk()
			{
				this.isActive = true;
				if (this.$store.state.user != false)
				{
					this.$router.push("/personal/");
				} else
				{
					this.$store.commit('openPhoneAuthPopup');
				}
			},
		},
	mounted()
	{
		const userAgent = window.navigator.userAgent;
		this.isMobileApp = userAgent.match(/mobileApp/i);
	}
};
</script>

<style lang="scss">
.bottom-navigation
{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 64px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 40px;
	z-index: 100;
	background: #FFFFFF;
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
}

.bottom-navigation__item
{
	a
	{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
	}

	img
	{
		width: 24px;
		height: 24px;
	}

	span
	{
		font-family: $mainFontNew;
		font-style: normal;
		font-weight: 700;
		font-size: 11px;
		line-height: 14px;
		text-align: center;
		color: #979797;
	}

	a.router-link-active
	{
		span
		{color: #40AE49;}

		img
		{filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(324%) hue-rotate(74deg) brightness(98%) contrast(89%);}
	}
}

.bottom-navigation__item-cart
{position: relative;}

span.bottom-navigation__item-count
{
	position: absolute;
	top: 9px;
	right: 38px;
	width: 18px;
	height: 14px;
	background: #FD470E;
	border-radius: 14px;
	font-family: $mainFontNew;
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	line-height: 14px;
	text-align: center;
	color: #FFFFFF;
}

.bottom-navigation--application
{display: none;}

.bottom-navigation--personal
{display: none;}

@media (min-width: 1000px)
{
	.bottom-navigation--chat
	{display: none;}
	.bottom-navigation--menu
	{display: none;}
	.bottom-navigation--cart
	{display: none;}
	.bottom-navigation--order
	{display: none;}
	.bottom-navigation--breakfast
	{display: none;}
	.bottom-navigation--constructor
	{display: none;}
}
</style>
