<template>
	<div class="not-found-page__wrapp">
		<main class="not-found-page">
			<div
				class="container-white not-found-page__container"
				:class="{'mobile-container': mobileCheck()}"
			>
				<div class="container-base">
					<Breadcrumbs
						class="not-found-page__breadcrumbs"
						:linkArr="linkArr"
					/>
					<SectionTitle
						class="not-found-page__title"
					>
						404
					</SectionTitle>
				</div>
			</div>
			<div class="not-found-page__content">
				<span class="not-found-page__content-text">
					Что-то пошло не так. Попробуйте обновить страницу или перейдите на главную
				</span>
				<a
					href="/application/"
					class="not-found-page__content-button"
				>
					Перейти на главную
				</a>
			</div>
			<BottomNavigation class="not-found-page__bottom-navigation"/>
		</main>
	</div>
</template>

<script>
import '@/assets/scss/main.scss';
import BottomNavigation from "@/components/nav/BottomNavigation.vue";
import {mobileCheck} from "@/helpers";

export default {
	name: 'NotFoundPage',
	components: {
		BottomNavigation,
		Breadcrumbs: () => import('@/components/block/Breadcrumbs.old.vue'),
		SectionTitle: () => import("../components/block/SectionTitle.vue"),
	},
	methods: {
		mobileCheck
	},
	data: () => ({
		linkArr:
			[
				{
					name: "404",
					link: "/404"
				},
			],
	}),
}
</script>

<style lang="scss">
.not-found-page__wrapp
{
	height: 100vh;
}

.not-found-page
{
	position: relative;
	height: 100%;
	background-image: url("~@/assets/img/notFound.svg");
	background-position: center;
	background-color: white;
	background-repeat: no-repeat;

	&__container
	{
		height: 100%;
		background-color: transparent;
	}

	&__title
	{max-width: 840px}

	&__content
	{
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 160px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		max-width: 328px;
		width: 100%;

		&-text
		{
			font-family: $mainFontNew;
			color: $greyLightText;
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
		}

		&-button
		{
			background-color: rgba($green, .15);
			color: $green;
			border: none;
			font-family: $mainFontBoldNew;
			font-weight: 700;
			font-size: 16px;
			height: 56px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 18px 0;
			width: calc(100% - 17px);
			border-radius: 8px;
		}
	}
}

@media (min-width: 1000px)
{
	.not-found-page
	{
		&__bottom-navigation
		{display: none;}
	}
}

@media (max-width: 1000px)
{
	.not-found-page
	{
		&__breadcrumbs
		{display: none !important;}
	}
}

@media (max-width: 567px)
{
	.not-found-page
	{
		background-image: url("~@/assets/img/notFoundMobile.svg");
		background-size: 100%;

		&__content
		{bottom: 84px;}
	}
}
</style>
